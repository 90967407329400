// src/hooks/useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // Redirect to login if the user is not authenticated
        navigate('/');
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [navigate]);
};

export default useAuth;
