// src/Sidebar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import './Sidebar.css'; // Import your styles
import logo from './assets/logop.png'; // Replace with your actual logo file path
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase auth

const Sidebar = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful, navigate to the login page or homepage
                navigate('/');
            })
            .catch((error) => {
                // Handle any errors that occur during sign-out
                console.error('Error logging out:', error);
            });
    };

    return (
        <aside className="sidebar">
            <div className="sidebar-header">
                <img src={logo} alt="The Campus Logo" className="sidebar-logo" />
                <h2>The Campus Ads</h2>
            </div>
            <nav className="sidebar-nav">
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/campaigns">Manage Campaigns</Link>
                <Link to="/analytics">Analytics</Link>
                <Link to="/payments">Payments</Link>
                <Link to="/settings">Account Settings</Link>
                <Link to="/support">Support</Link>
                <div className="logout-section">
                <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
            </nav>
            
        </aside>
    );
};

export default Sidebar;
