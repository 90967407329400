import React, { useState, useEffect } from 'react'; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, set, get } from 'firebase/database'; // Import `get` for reading data
import './ProfilePage.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom


const ProfilePage = ({ userId }) => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [formData, setFormData] = useState({
        businessUsername: '',
        firstName: '',
        lastName: '',
        bio: '',
        address: '',
        phone: '',
        website: '',
        youtube: '',
        facebook: '',
        twitter: '',
        dateOfEstablishment: '',
        businessType: ''
    });
    const [usernameError, setUsernameError] = useState(''); // State for username error

    const storage = getStorage();
    const database = getDatabase();

    const handleCoverPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverPhoto(file);
        }
    };

    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePhoto(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Check for duplicate username if the changed field is businessUsername
        if (name === 'businessUsername') {
            checkUsername(value);
        }
    };

    // Function to check if username already exists
    const checkUsername = async (username) => {
        try {
            // Fetch usernames from both paths
            const profilesRef = dbRef(database, `profiles/`);
            const businessesRef = dbRef(database, `business/`);

            const [profilesSnapshot, businessesSnapshot] = await Promise.all([
                get(profilesRef),
                get(businessesRef)
            ]);

            // Extract existing usernames from both snapshots
            const existingUsernames = [];

            // Check profiles
            profilesSnapshot.forEach(profile => {
                const profileData = profile.val();
                if (profileData.username) {
                    existingUsernames.push(profileData.username);
                }
            });

            // Check businesses
            businessesSnapshot.forEach(business => {
                const businessData = business.val();
                if (businessData.businessUsername) {
                    existingUsernames.push(businessData.businessUsername);
                }
            });

            // Check if the entered username exists
            if (existingUsernames.includes(username)) {
                setUsernameError('This username is already taken.');
            } else {
                setUsernameError('');
            }
        } catch (error) {
            console.error("Error checking username:", error);
        }
    };

    const handleSave = async () => {
        try {
            if (!coverPhoto || !profilePhoto) {
                console.error("Cover photo or profile photo is not set.");
                return;
            }

            if (!userId) {
                console.error("User ID is not defined.");
                return;
            }

            const coverPhotoRef = ref(storage, `coverPhotos/${userId}/${Date.now()}_${coverPhoto.name}`);
            const profilePhotoRef = ref(storage, `profilePhotos/${userId}/${Date.now()}_${profilePhoto.name}`);

            const coverSnapshot = await uploadBytes(coverPhotoRef, coverPhoto);
            const profileSnapshot = await uploadBytes(profilePhotoRef, profilePhoto);

            const coverPhotoURL = await getDownloadURL(coverSnapshot.ref);
            const profilePhotoURL = await getDownloadURL(profileSnapshot.ref);

            const userRef = dbRef(database, `business/${userId}`);
            await set(userRef, {
                ...formData,
                coverPhotoURL,
                profilePhotoURL
            });

            console.log("Profile data saved:", {
                ...formData,
                coverPhotoURL,
                profilePhotoURL
            });
            navigate('/dashboard');

        } catch (error) {
            console.error("Error saving profile data:", error);
        }
    };

    useEffect(() => {
        return () => {
            if (coverPhoto) {
                URL.revokeObjectURL(coverPhoto);
            }
            if (profilePhoto) {
                URL.revokeObjectURL(profilePhoto);
            }
        };
    }, [coverPhoto, profilePhoto]);

    return (
        <div className="profile-page">
            <div className="profile-card">
                <div className="cover-photo-container">
                    {coverPhoto ? (
                        <img src={URL.createObjectURL(coverPhoto)} alt="Cover" className="cover-photo" />
                    ) : (
                        <div className="cover-photo-placeholder" onClick={() => document.getElementById('coverPhotoInput').click()}>
                            <span className="camera-icon">📸</span>
                        </div>
                    )}
                    <input
                        type="file"
                        id="coverPhotoInput"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleCoverPhotoChange}
                    />
                </div>

                <div className="profile-photo-container">
                    {profilePhoto ? (
                        <img src={URL.createObjectURL(profilePhoto)} alt="Profile" className="profile-photo" />
                    ) : (
                        <div className="profile-photo-placeholder" onClick={() => document.getElementById('profilePhotoInput').click()}>
                            <span className="camera-icon">📸</span>
                        </div>
                    )}
                    <input
                        type="file"
                        id="profilePhotoInput"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleProfilePhotoChange}
                    />
                </div>

                <form className="profile-form">
                    <input
                        type="text"
                        name="businessUsername"
                        placeholder="Business Username"
                        value={formData.businessUsername}
                        onChange={handleChange}
                    />
                    {usernameError && <span className="error-message">{usernameError}</span>} {/* Display error message */}

                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    <textarea
                        name="bio"
                        placeholder="Bio Tagline"
                        value={formData.bio}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="website"
                        placeholder="Website (if any)"
                        value={formData.website}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="youtube"
                        placeholder="YouTube Link"
                        value={formData.youtube}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="facebook"
                        placeholder="Facebook Link"
                        value={formData.facebook}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="twitter"
                        placeholder="Twitter Link"
                        value={formData.twitter}
                        onChange={handleChange}
                    />
                    <input
                        type="date"
                        name="dateOfEstablishment"
                        value={formData.dateOfEstablishment}
                        onChange={handleChange}
                    />
                    <select
                        name="businessType"
                        value={formData.businessType}
                        onChange={handleChange}
                    >
                        <option value="">Select Business Type</option>
                        <option value="education">Education</option>
                        <option value="election">Election</option>
                        <option value="govt_body">Government Body</option>
                        <option value="healthcare">Healthcare</option>
                        <option value="technology">Technology</option>
                        <option value="finance">Finance</option>
                        <option value="real_estate">Real Estate</option>
                        <option value="ecommerce">E-commerce</option>
                        <option value="consulting">Consulting</option>
                        <option value="non_profit">Non-Profit Organization</option>
                        <option value="food_service">Food Service</option>
                        <option value="travel_tourism">Travel and Tourism</option>
                        <option value="manufacturing">Manufacturing</option>
                        <option value="construction">Construction</option>
                        <option value="retail">Retail</option>
                        <option value="media_entertainment">Media and Entertainment</option>
                        <option value="transportation">Transportation</option>
                        <option value="telecommunications">Telecommunications</option>
                        <option value="agriculture">Agriculture</option>
                    </select>
                    <button type="button" className="save-button" onClick={handleSave}>
                        Save Profile
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ProfilePage;
