// src/Campaigns.js
import React, { useEffect, useState } from 'react';
import { database,auth } from './firebaseConfig'; // Import your Firebase configuration
import { collection, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './Campaigns.css';

const Campaigns = () => {
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [expiredCampaigns, setExpiredCampaigns] = useState([]);
  const [showExpired, setShowExpired] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const userId = auth.currentUser.uid; // Replace with the actual user ID logic

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        console.log('Fetching active campaigns...');
        const activeCampaignsSnapshot = await getDocs(collection(database, `businessAds/${userId}/activeCampaigns`));
        const activeCampaignsData = activeCampaignsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setActiveCampaigns(activeCampaignsData);
        console.log('Active campaigns fetched:', activeCampaignsData);

        console.log('Fetching expired campaigns...');
        const expiredCampaignsSnapshot = await getDocs(collection(database, `businessAds/${userId}/expiredCampaigns`));
        const expiredCampaignsData = expiredCampaignsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setExpiredCampaigns(expiredCampaignsData);
        console.log('Expired campaigns fetched:', expiredCampaignsData);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, [userId]);

  const handleAddCampaign = () => {
    // Navigate to the new campaign page
    navigate('/new-campaign');
  };

  const renderCampaigns = (campaigns) => {
    if (campaigns.length === 0) {
      return <p>No running campaigns</p>;
    }

    return campaigns.map(campaign => (
      <div key={campaign.id} className="campaign-card">
        <h3>{campaign.title}</h3>
        <p>{campaign.description}</p>
        <p>Remaining Budget: ${campaign.remainingBudget || 0}</p>
        <p>End Date: {new Date(campaign.endDate).toLocaleDateString()}</p>
      </div>
    ));
  };

  return (
    <div className="campaigns-container">
      <div className="header">
        <h1>Campaigns</h1>
        <button className="add-campaign-button" onClick={handleAddCampaign}>
          <FontAwesomeIcon icon={faPlus} /> Add Campaign
        </button>
      </div>

      <div className="tabs">
        <button onClick={() => setShowExpired(false)} className={!showExpired ? 'active-tab' : ''}>Active Campaigns</button>
        <button onClick={() => setShowExpired(true)} className={showExpired ? 'active-tab' : ''}>Expired Campaigns</button>
      </div>

      <div className="campaigns-list">
        {showExpired ? renderCampaigns(expiredCampaigns) : renderCampaigns(activeCampaigns)}
      </div>
    </div>
  );
};

export default Campaigns;
