// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import './dashboard.css';
import { getDatabase, ref, get, child } from "firebase/database";
import { useNavigate } from "react-router-dom";
import useAuth from '../src/hooks/useAuth';

const Dashboard = ({ userId }) => {
  useAuth();
  const [profile, setProfile] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileAndCampaigns = async () => {
      setLoading(true); // Set loading state
      try {
        const dbRef = ref(getDatabase());

        // Fetch user profile
        const profileSnapshot = await get(child(dbRef, `business/${userId}`));
        setProfile(profileSnapshot.exists() ? profileSnapshot.val() : null);

        // Fetch campaigns associated with the user
        const campaignsSnapshot = await get(child(dbRef, `campaigns/${userId}`));
        setCampaigns(campaignsSnapshot.exists() ? campaignsSnapshot.val() : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchProfileAndCampaigns();
  }, [userId]);

  const handleCreateProfile = () => {
    navigate("/create-profile"); // Navigate to create profile page
  };

  if (loading) {
    return <p>Loading...</p>; // Loading state
  }

  const totalSpends = campaigns.reduce((total, campaign) => total + (campaign.budget || 0), 0);

  return (
    <div className="dashboard-container">
      <div className="main-content">
        <header className="top-bar">
          <input type="text" placeholder="Search..." className="search-bar" />
          <div className="user-info">
            <img
              src={profile?.profilePhotoURL || "/user-avatar.png"}
              alt="User Avatar"
              className="user-avatar"
            />
            <div className="user-dropdown">
              <span>
                Welcome, {profile ? `${profile.firstName} ${profile.lastName}` : "User"}
              </span>
              <div className="dropdown-content">
                <a onClick={() => navigate('/profile')}>Profile</a>
                <a onClick={() => navigate('/')}>Logout</a>
              </div>
            </div>
          </div>
        </header>

        {profile ? (
          <div>
            <div className="dashboard-overview">
              <div className="dashboard-card">
                <h3>Active Campaigns</h3>
                <p>{campaigns.length}</p> {/* Show number of campaigns */}
              </div>
              <div className="dashboard-card">
                <h3>Total Impressions</h3>
                <p>{campaigns.reduce((total, campaign) => total + (campaign.impressions || 0), 0)}</p>
              </div>
              <div className="dashboard-card">
                <h3>Total Clicks</h3>
                <p>{campaigns.reduce((total, campaign) => total + (campaign.clicks || 0), 0)}</p>
              </div>
              <div className="dashboard-card">
                <h3>Budget Spent</h3>
                <p>${totalSpends.toFixed(2)}</p> {/* Format budget to two decimal places */}
              </div>
            </div>

            <div className="campaign-management">
              <h2>Recent Campaigns</h2>
              {campaigns.length > 0 ? (
                <table className="campaign-table">
                  <thead>
                    <tr>
                      <th>Campaign Name</th>
                      <th>Status</th>
                      <th>Impressions</th>
                      <th>Clicks</th>
                      <th>Budget</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.map((campaign, index) => (
                      <tr key={index}>
                        <td>{campaign.name}</td>
                        <td>{campaign.status}</td>
                        <td>{campaign.impressions}</td>
                        <td>{campaign.clicks}</td>
                        <td>${campaign.budget.toFixed(2)}</td> {/* Format budget to two decimal places */}
                        <td>
                          <button>Edit</button>
                          <button>Pause</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-campaigns">
                  <h3>No Campaigns Running</h3>
                  <p>Total Spent: $0.00</p> {/* Format total spent to two decimal places */}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="card-tile">
            <h3>No profile found</h3>
            <p>Create a new business profile to get started.</p>
            <button onClick={handleCreateProfile}>Create Profile</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
