// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './Login';
import Dashboard from './dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebaseConfig';
import Profile from './Profile';
import Campaigns from './Campaigns';
import ProfilePage from './ProfilePage';
import MainLayout from './MainLayout';
import NewCampaign from './NewCampaign';

function App() {
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<MainLayout><Dashboard userId={userId} /></MainLayout>} />
          <Route path="/new-campaign" element={<MainLayout><NewCampaign userId={userId} /></MainLayout>} />
          <Route path="/profile" element={<MainLayout><Profile /></MainLayout>} />
          <Route path="/campaigns" element={<MainLayout><Campaigns /></MainLayout>} />
          <Route path="/create-profile" element={<MainLayout><ProfilePage userId={userId} /></MainLayout>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
