// src/services/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getDatabase } from "firebase/database"; // Import getDatabase
import { getStorage } from "firebase/storage"; // Import getStorage

const firebaseConfig = {
    apiKey: "AIzaSyAikLBmzW2i9lDEJKM8zb7aMVwB94SQTEE",
    authDomain: "reclectionindia.firebaseapp.com",
    databaseURL: "https://reclectionindia-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "reclectionindia",
    storageBucket: "reclectionindia.appspot.com",
    messagingSenderId: "479797569905",
    appId: "1:479797569905:web:dc440ad78fd3f5d7f3aed0",
    measurementId: "G-ZPWJXP6KNX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize auth
const database = getDatabase(app); // Initialize the database
const storage = getStorage(app); // Initialize the storage

export { app, auth, database, storage }; // Export app, auth, database, and storage
