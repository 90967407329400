import React, { useState } from 'react';
import { database, storage } from './firebaseConfig';
import { ref, set } from 'firebase/database';  // Importing `set` from `firebase/database`
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './NewCampaign.css';
import axios from 'axios';

const NewCampaign = ({ userId }) => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [audience, setAudience] = useState('');
  const [location, setLocation] = useState('');
  const [budget, setBudget] = useState(500);
  const [campaignPeriod, setCampaignPeriod] = useState(3);
  const [startDate, setStartDate] = useState('');
  const [media, setMedia] = useState(null);
  const [contactMethod, setContactMethod] = useState('Get Call');
  const [contactField, setContactField] = useState('');

  const audienceOptions = [
    "BSc First Year", "BSc Second Year", "BSc Third Year", "BSc Fourth Year",
    "BA First Year", "BA Second Year", "BA Third Year", "BA Fourth Year",
    "BCom First Year", "BCom Second Year", "BCom Third Year", "BCom Fourth Year",
    "BCA First Year", "BCA Second Year", "BCA Third Year", "BCA Fourth Year",
    "All Graduates", "All BSc", "All BA", "All BCom", "All BCA"
  ];

  const locationOptions = [
    "Jaipur", "Dausa", "Ajmer", "Jodhpur", "Bikaner", "Udaipur",
    "Kota", "All Rajasthan", "All India"
  ];

  const handleCreateCampaign = async () => {
    // Validate media
    handleNavigateToPayment(budget); // Navigate to payment

    if (!media || (media.type !== 'video/mp4' && !['image/jpeg', 'image/jpg'].includes(media.type))) {
      alert("Please upload a valid media file (1 video in .mp4 format or 2 photos in .jpg/jpeg format)");
      return;
    }

    // Validate startDate
    const parsedStartDate = new Date(startDate);
    if (isNaN(parsedStartDate.getTime())) {
      alert("Please select a valid start date.");
      return;
    }

    try {
      // Upload media and get URL
      const mediaStorageRef = storageRef(storage, `campaignMedia/${userId}/${Date.now()}_${media.name}`);
      await uploadBytes(mediaStorageRef, media);
      const mediaURL = await getDownloadURL(mediaStorageRef);

      // Create campaign object
      const newCampaign = {
        userId,
        name,
        title,
        description,
        audience,
        location,
        budget,
        campaignPeriod,
        startDate: parsedStartDate.toISOString(),
        timestamp: Date.now(),
        mediaURL,
        contactMethod,
        contactField,
        views: [0], // Array to track user IDs or details who viewed the ad
        impressions: [0], // Array to track each time the ad is displayed
        clicks: [0], // Array to track user IDs or details who clicked the ad
        activeStatus: true, // Boolean to indicate if the campaign is currently active
        endDate: null, // Optional field to specify when the campaign ends, if needed
        engagementRate: 0.0 // Calculated rate of engagement based on clicks and impressions
    };
    

      // Save to Firebase Realtime Database
      const campaignRef = ref(database, `businessAds/${userId}/activeCampaigns/${Date.now()}`); // Using timestamp as unique key
      await set(campaignRef, newCampaign); // Storing campaign data
      alert('Campaign created successfully');
    } catch (error) {
      console.error('Error creating campaign:', error);
      alert('Failed to create campaign');
    }
  };

  const handleNavigateToPayment = async (amount) => {
    const payload = {
      amount,
      purpose: "Campaign Payment",
      buyer_name: name,
      email: userId,
      phone: "1234567890", // Replace with user's phone number
      redirect_url: "http://localhost:3000/",
      webhook: "http://your-website.com/webhook" // Optional webhook
    };

    try {
      const response = await axios.post('https://api.instamojo.com/v2/payment_requests/', payload, {
        headers: {
          'X-Auth-Token': 'd5951ec957a0b2c327ede6b08882bc9e',
          'Content-Type': 'application/json'
        }
      });

      // Redirect user to the payment URL
      window.location.href = response.data.payment_request.longurl;
    } catch (error) {
      console.error('Payment error:', error);
      alert('Payment initiation failed');
    }
  };

  const handleBudgetChange = (value) => {
    setBudget(value);
    if (value < 5000) {
      setCampaignPeriod(Math.min(campaignPeriod, 10));
    } else if (value < 12000) {
      setCampaignPeriod(Math.min(campaignPeriod, 20));
    } else {
      setCampaignPeriod(Math.max(17, campaignPeriod));
    }
  };

  const calculateDailyReach = () => {
    const dailyBudget = budget / campaignPeriod;
    let multiplier = 4.5;

    if (dailyBudget > 1500 && dailyBudget <= 2000) {
      multiplier = 4.2;
    } else if (dailyBudget > 2000 && dailyBudget <= 4500) {
      multiplier = 3.9;
    } else if (dailyBudget > 4500) {
      multiplier = 3.4;
    }

    return dailyBudget * multiplier;
  };

  const handleMediaUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.type.includes('image') && file.size <= 2 * 1024 * 1024) {
      setMedia(file);
    } else if (file.type.includes('video') && file.size <= 10 * 1024 * 1024) {
      setMedia(file);
    } else {
      alert("Please upload a valid media file (up to 2MB for images, up to 10MB for videos)");
      event.target.value = ''; // Clear the input
      setMedia(null); // Clear the media state
    }
  };

  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
    setContactField(''); // Reset contact field
  };

  return (
    <div className="new-campaign-container">
      <h1>Create New Campaign</h1>
      <div className="form-grid">
        <div className="form-group">
          <label>Campaign Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Campaign Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-group full-width">
          <label>Campaign Description</label>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Targeted Audience</label>
          <select value={audience} onChange={(e) => setAudience(e.target.value)}>
            <option value="">Select Audience</option>
            {audienceOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Location</label>
          <select value={location} onChange={(e) => setLocation(e.target.value)}>
            <option value="">Select Location</option>
            {locationOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Budget (₹): {budget}</label>
          <input
            type="range"
            min={500}
            max={20000}
            step={500}
            value={budget}
            onChange={(e) => handleBudgetChange(parseInt(e.target.value, 10))}
          />
        </div>
        <div className="form-group">
          <label>Campaign Period (Days): {campaignPeriod}</label>
          <input
            type="range"
            min={3}
            max={budget < 5000 ? 10 : budget < 12000 ? 20 : 30}
            value={campaignPeriod}
            onChange={(e) => setCampaignPeriod(parseInt(e.target.value, 10))}
          />
        </div>
        <div className="form-group">
          <label>Start Date</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Upload Media (1 video or up to 2 photos)</label>
          <input type="file" accept="video/mp4, image/jpeg, image/jpg" onChange={handleMediaUpload} />
          <FontAwesomeIcon icon={faPlus} className="upload-icon" />
        </div>
        
        <div className="form-group">
          <label>Contact Method</label>
          <select value={contactMethod} onChange={handleContactMethodChange}>
            <option value="Get Call">Get Call</option>
            <option value="Get Email">Get Email</option>
            <option value="Get SMS">Get SMS</option>
          </select>
        </div>
        <div className="form-group">
          <label>Contact Field</label>
          <input type="text" value={contactField} onChange={(e) => setContactField(e.target.value)} />
        </div>
      </div>
      <button onClick={handleCreateCampaign} className="create-campaign-button">Create Campaign</button>
      <div className="daily-reach">
        <p>Estimated Daily Reach: {calculateDailyReach()}</p>
      </div>
    </div>
  );
};

export default NewCampaign;
