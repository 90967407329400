// src/Login.js
import React, { useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  onAuthStateChanged, 
  GoogleAuthProvider, 
  signInWithPopup 
} from 'firebase/auth';
import { auth } from './firebaseConfig';
import './Login.css';

const Login = () => {
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Redirect to dashboard if the user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        window.location.href = '/dashboard';
      }
    });
    return () => unsubscribe();
  }, []);

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = '/dashboard';
    } catch (error) {
      setError(error.message || 'Failed to log in. Please check your email and password.');
    } finally {
      setLoading(false);
    }
  };

  // Handle signup
  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      window.location.href = '/dashboard';
    } catch (error) {
      setError(error.message || 'Failed to create an account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      window.location.href = '/dashboard';
    } catch (error) {
      setError(error.message || 'Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>The Campus Ads</h1>
        <form onSubmit={isCreatingAccount ? handleSignup : handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {isCreatingAccount && (
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}
          <button type="submit" disabled={loading}>
            {loading ? 'Processing...' : isCreatingAccount ? 'Create Account' : 'Login'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <div className="google-signin">
          <button onClick={handleGoogleSignIn} className="google-signin-button">
            Sign in with Google
          </button>
        </div>
        <div className="toggle-form">
          {isCreatingAccount ? (
            <p>
              Already have an account?{' '}
              <span onClick={() => setIsCreatingAccount(false)}>Log In</span>
            </p>
          ) : (
            <p>
              Don't have an account?{' '}
              <span onClick={() => setIsCreatingAccount(true)}>Create Account</span>
            </p>
          )}
        </div>
        <div className="extra-links">
          <a href="/forgot-password">Forgot Password?</a>
          <a href="/help">Help</a>
        </div>
        <div className="footer-links">
          <a href="/privacy-policy">Privacy Policy</a> | 
          <a href="/terms-conditions">Terms and Conditions</a> | 
          <a href="/contact-us">Contact Us</a> | 
          <a href="/about-us">About Us</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
