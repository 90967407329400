// src/Profile.js
import React from 'react';
import './Profile.css';

// Default avatar image URL (you can replace it with your own)
const DEFAULT_AVATAR = "https://via.placeholder.com/120";

const Profile = ({ userData }) => {
  // Check if userData is defined; if not, initialize it to an empty object
  const safeUserData = userData || {};

  // Use the default avatar if profilePicture is undefined
  const profilePicture = safeUserData.profilePicture || DEFAULT_AVATAR;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <img src={profilePicture} alt="Profile" className="profile-picture" />
        <div className="profile-info">
          <h1>{safeUserData.name || "No Name Provided"}</h1>
          <h2>{safeUserData.title || "No Title Provided"}</h2>
          <p>{safeUserData.location || "No Location Provided"}</p>
          <button className="connect-button">Connect</button>
        </div>
      </div>

      <div className="profile-details">
        <h3>About</h3>
        <p>{safeUserData.about || "No About Information"}</p>

        <h3>Experience</h3>
        <ul className="experience-list">
          {safeUserData.experience && safeUserData.experience.length > 0 ? (
            safeUserData.experience.map((job, index) => (
              <li key={index} className="experience-item">
                <h4>{job.position}</h4>
                <p>{job.company} | {job.date}</p>
                <p>{job.description}</p>
              </li>
            ))
          ) : (
            <p>No Experience Information</p>
          )}
        </ul>

        <h3>Education</h3>
        <ul className="education-list">
          {safeUserData.education && safeUserData.education.length > 0 ? (
            safeUserData.education.map((school, index) => (
              <li key={index} className="education-item">
                <h4>{school.degree} in {school.field}</h4>
                <p>{school.institution} | {school.year}</p>
              </li>
            ))
          ) : (
            <p>No Education Information</p>
          )}
        </ul>

        <h3>Skills</h3>
        <div className="skills-container">
          {safeUserData.skills && safeUserData.skills.length > 0 ? (
            safeUserData.skills.map((skill, index) => (
              <span key={index} className="skill">{skill}</span>
            ))
          ) : (
            <p>No Skills Information</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
